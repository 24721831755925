import React, { useState, createContext, useContext } from 'react';

// Create a context
const SettingsContext = createContext();

// Create the provider component
export class SettingsProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matchPercentage: 0.6,
      setMatchPercentage: this.setMatchPercentage,
    };
  }
  setMatchPercentage = (newMatchPercentage) => {
    this.setState({ matchPercentage: newMatchPercentage });
  };

  render() {
    return (
      <SettingsContext.Provider value={this.state}>
        {this.props.children}
      </SettingsContext.Provider>
    );
  }
}

// Hook to use the settings in functional components
export const useSettings = () => {
  return useContext(SettingsContext);
};
