

// Utility: Sanitizes strings using regex
const sanitizeString = (str) => str.replace(/[^a-zA-Z0-9_\-@.]/g, '').trim();

// Utility: Validates Base64 image format
const validateBase64Image = (image) => {

    return typeof image === 'string' && image.match(/^data:image\/(png|jpeg|jpg);base64,[A-Za-z0-9+/=]+$/);
};

// Utility: Validates username and password
const validateUsernameAndPassword = (username, password) => {
    return (
        typeof username === 'string' &&
        typeof password === 'string' &&
        username.length <= 50 &&
        password.length <= 50 &&
        sanitizeString(username) === username &&
        sanitizeString(password) === password
    );
};

// Helper: Sends API requests
const sendRequest = async (url, method, formData) => {
    try {
        const response = await fetch(url, { method, body: formData });
        const result = await response.json();
        if (!response.ok) {
            console.error(`Error from ${url}:`, result.error);
            throw new Error(result.error);
        }
        return result;
    } catch (error) {
        console.error(`Error during fetch to ${url}:`, error);
        throw error;
    }
};

// Function: Upload image
async function uploadImage(username, password, base64Image) {
    if (
        !validateUsernameAndPassword(username, password) ||
        !validateBase64Image(base64Image)
    ) {
        throw new Error("Invalid input for image upload.");
    }

    const formData = new FormData();
    formData.append('username', sanitizeString(username));
    formData.append('password', sanitizeString(password));
    formData.append('image', base64Image.split(',')[1]); // Strip data URL prefix

    return sendRequest('https://api.facevault.uk/upload', 'POST', formData);
}

// Function: Match image
async function matchImage(username, password, base64Image) {
    if (
        !validateUsernameAndPassword(username, password) ||
        !validateBase64Image(base64Image)
    ) {
        throw new Error("Invalid input for image match.");
    }
    console.log(base64Image.split(',')[1])

    const formData = new FormData();
    formData.append('username', sanitizeString(username));
    formData.append('password', sanitizeString(password));
    formData.append('image', base64Image.split(',')[1]);

    return sendRequest('https://api.facevault.uk/match', 'POST', formData);
}

// Function: Upload user
async function uploadUser(username, password) {
    if (!validateUsernameAndPassword(username, password)) {
        throw new Error("Invalid input for user upload.");
    }

    const formData = new FormData();
    formData.append('username', sanitizeString(username));
    formData.append('password', sanitizeString(password));

    return sendRequest('https://api.facevault.uk/upload_user', 'POST', formData);
}

// Function: Upload file
async function uploadFile(username, password, file) {
    if (!validateUsernameAndPassword(username, password) || !(file instanceof File)) {
        throw new Error("Invalid input for file upload.");
    }

    const formData = new FormData();
    formData.append('username', sanitizeString(username));
    formData.append('password', sanitizeString(password));
    formData.append('file', file);

    return sendRequest('https://api.facevault.uk/upload_file', 'POST', formData);
}

// Function: Return files
async function returnFiles(username, password) {
    if (!validateUsernameAndPassword(username, password)) {
        throw new Error("Invalid input for returning files.");
    }

    const formData = new FormData();
    formData.append('username', sanitizeString(username));
    formData.append('password', sanitizeString(password));

    try {
        const response = await fetch('https://api.facevault.uk/return_files', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status} - ${response.statusText}`);
        }

        // Safely extract the Content-Disposition header
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'downloaded_file.tar'; // Default filename
        if (contentDisposition) {
            const match = contentDisposition.match(/filename="?([^"]+)"?/);
            if (match && match[1]) {
                fileName = match[1];
            } else {
                console.warn("Failed to parse filename from Content-Disposition header:", contentDisposition);
            }
        }

        // Process binary data as Blob
        const fileData = await response.blob();

        return {
            fileName,
            fileData, // Blob containing binary data
        };
    } catch (error) {
        console.error("Error during fetch to /return_files:", error);
        throw error;
    }
}
async function replaceDirectory(username, password, file) {
    // Log the received inputs
    console.log("Received inputs:", { username, password, file });

    // Validate the inputs
    if (!validateUsernameAndPassword(username, password) || !(file instanceof File)) {
        console.error("Invalid input for replacing directory:", { username, password, file });
        throw new Error("Invalid input for replacing directory.");
    }

    // Log validation success
    console.log("Inputs validated successfully.");

    // Convert the file to base64
    const base64File = await convertFileToBase64(file);
    if (!base64File) {
        console.error("Failed to convert file to base64.");
        throw new Error("Failed to convert file to base64.");
    }

    // Create form data for the request
    const formData = new FormData();
    formData.append('username', sanitizeString(username));
    formData.append('password', sanitizeString(password));
    formData.append('tar_file', base64File);  // Send base64 encoded file under 'tar_file'

    // Log form data before sending
    console.log("Form data prepared for the request:", {
        username: sanitizeString(username), 
        password: sanitizeString(password),
        file: file.name // Log the file name instead of the entire file for security
    });

    try {
        // Send the request
        const response = await sendRequest('https://api.facevault.uk/replace_directory', 'POST', formData);

        // Log the response
        console.log("Server response:", response);

        return response;
    } catch (error) {
        // Log the error if request fails
        console.error("Error during the request:", error);
        throw error;
    }
}

// Helper function to convert file to Base64
function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extract base64 part
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}




// Export functions for external use
module.exports = {replaceDirectory, uploadImage, matchImage, uploadUser, uploadFile, returnFiles };
