import React, { useState, useEffect } from 'react';
import { Button, Loading, Popover } from '@carbon/react';
import WebcamPage from '../pages/WebcamPage'; // Import WebcamPage.
import StoragePage from '../pages/StoragePage'; // Import StoragePage
import SetupPage from '../pages/SetupPage'; // Import SetupPage
import UnlockPage from '../pages/UnlockPage'; // Import UnlockPage
import eyelogo from '../images/eyelogo.png';
import eyelogoBlack from '../images/logoblack.png';
import { useCredentials } from '../provider/CredentialsProvider';

import FileManagerComponent from './FileManagerComponent'; 

export default function HomePage() {
  const [isGridPage, setIsGridPage] = useState(false);
  const [isScrollingDisabled, setIsScrollingDisabled] = useState(false);
  const [activePage, setActivePage] = useState('unlock'); // State to control active page
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const { username, password } = useCredentials();
 
  useEffect(() => {
    const handleScroll = () => {
      if (isScrollingDisabled) return;

      const topContainer = document.querySelector('.top-container') as HTMLElement;
      if (!topContainer) return;

      const scrollPosition = window.scrollY;
      const maxScroll = window.innerHeight / 2;

      // Parallax effect for the top container
      topContainer.style.opacity = `${1 - scrollPosition / maxScroll}`;
      topContainer.style.transform = `translateY(${scrollPosition * 0.5}px)`;

      const bottomContainer = document.querySelector('.bottom-container') as HTMLElement;
      const triggerPoint = bottomContainer.offsetTop + bottomContainer.offsetHeight / 2;

      if (scrollPosition > triggerPoint && !isGridPage) {
        setIsGridPage(true);
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
          const gridPage = document.querySelector('.grid-page') as HTMLElement;
          if (gridPage) {
            window.scrollTo({
              top: gridPage.offsetTop,
              behavior: 'smooth',
            });
          }
        }, 900);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isGridPage, isScrollingDisabled]);

  const scrollToTop = () => {
    setIsGridPage(false);
    document.body.style.overflow = 'auto';
    setIsScrollingDisabled(true);
    setTimeout(() => setIsScrollingDisabled(false), 1000);

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      const topContainer = document.querySelector('.top-container') as HTMLElement;
      if (topContainer) {
        topContainer.style.transition = 'transform 1.5s ease-out, opacity 1.5s ease-out';
        topContainer.style.transform = 'translateY(0)';
        topContainer.style.opacity = '1';
      }
    }, 0);
  };

  // Function to handle page switching
  const renderActivePage = () => {
    if (activePage === 'filemanager') {
      return <FileManagerComponent />;
    }

    switch (activePage) {
      case 'webcam':
        return <WebcamPage />;
      case 'storage':
        if (!username || !password) {
          // Alert the user to unlock first
          alert("Unlock first to access storage.");
          return  <UnlockPage onUnlockComplete={handleUnlockComplete} />; // Don't render StoragePage
        }
        return <StoragePage />;
      case 'setup':
        return <SetupPage onSetupComplete={handleSetupComplete} />;
      case 'unlock':
      default:
        return <UnlockPage onUnlockComplete={handleUnlockComplete} />;
    }
  };

  // Handle Unlock Completion
  const handleUnlockComplete = () => {
    setIsLoading(true); // Show loading spinner
    setTimeout(() => {
      setIsLoading(false); // Hide loading spinner after unlock is complete
      setActivePage('filemanager'); // Set to file manager page
    }, 2000); // Simulate loading time (adjust as needed)
  };

  const handleSetupComplete = () => {
    setIsLoading(true); // Show loading spinner
    setTimeout(() => {
      setIsLoading(false); // Hide loading spinner after unlock is complete
      setActivePage('unlock'); // Set to file manager page
    }, 2000); // Simulate loading time (adjust as needed)
  };

  return (
    <>
      <div className="container">
        <div className={`grid-vertical ${isGridPage ? 'no-scroll' : ''}`}>
          <div className="top-container">
            <div className="welcome-text">Welcome</div>
            <div className="image-container">
              <img src={eyelogo} style={{ width: '300px', height: 'auto' }} alt="Eye Logo" />
            </div>
          </div>

          <div className="bottom-container">
            <div className='disclaimer-container'>
                Disclaimer :  
              
                This application is a prototype created as part of a university
                project and is not affiliated with or endorsed by IBM. The data
                within this application is used solely for demonstration purposes.
              
              
                If you wish to have your data removed from the website, please
                contact us at: P2720530@my365.dmu.ac.uk
              
            </div>
            <div className="image-container2">
              <img src={eyelogoBlack} style={{ width: '600px', height: 'auto', backgroundColor: 'white' }} alt="Eye Logo" />
            </div>
          </div>

          {/* Grid Page */}
          <div className={`grid-page ${isGridPage ? 'visible' : 'hidden'}`}>
            <div className="grid-container">
              <div className="grid-item item-1">
                {/* Vertical List of Buttons */}
                <Button kind="ghost" className="custom-button" onClick={scrollToTop}>Home</Button>
                <Button kind="ghost" className="custom-button" onClick={() => setActivePage('unlock')}>Unlock</Button>
                <Button kind="ghost" className="custom-button" onClick={() => setActivePage('webcam')}>Webcam Config</Button>
                <Button kind="ghost" className="custom-button" onClick={() => setActivePage('storage')}>Storage Config</Button>
                <Button kind="ghost" className="custom-button" onClick={() => setActivePage('setup')}>Setup</Button>
              </div>

              {/* Dynamic Page Rendering */}
              <div className="grid-item item-2">
                {isLoading ? <Loading withOverlay={true} /> : renderActivePage()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>{`
        * {
          background-color: black;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: 'IBM Plex Sans', sans-serif;
        }
         
       

        .container::-webkit-scrollbar { 
          display: none; 
        }

        body {
          overflow-x: hidden;
          height: 100vh;
          margin: 0;
          padding: 0;
        }

        .grid-vertical {
          display: flex;
          flex-direction: column;
          height: 300vh;
          position: relative;
          transition: overflow 0.3s;
        }

        .no-scroll {
          overflow: hidden;
        }

        .top-container {
          position: sticky;
          top: 0;
          width: 100%;
          height: 50vh;
          background-color: black;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          color: white;
          font-size: 48px;
          transition: opacity 1.5s ease-out, transform 1.5s ease-out;
          z-index: 2;
        }

        .welcome-text {
          text-align: left;
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 20px;
        }
        .disclaimer-container{
          text-align: left;
          background-color: white;
          color: black;
          font-size: 24px;
          font-family: 'IBM Plex Sans', sans-serif;
        }
        .image-container {
          flex: 1;
          display: flex;
          justify-content: center;
        }

        .image-container2 {
          flex: 1;
          display: flex;
          justify-content: center;
          background-color: white;
        }

        .bottom-container {
          width: 100%;
          height: 150vh;
          background-color: white;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 20px;
          color: black;
          font-size: 24px;
          position: relative;
          z-index: 4;
        }

        .grid-page {
          height: 100vh;
          background-color: black;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          transition: opacity 1.5s ease, transform 1.5s ease;
        }

        .grid-page.hidden {
          opacity: 0;
          transform: translateY(50px);
        }

        .grid-page.visible {
          opacity: 1;
          transform: translateY(0);
        }

        .grid-container {
          display: grid;
          grid-template-columns: 30% 70%;  /* 0% for the first column to remove the gap */
          grid-template-rows: auto 50px;
          grid-gap: 20px;
          width: 100%;
          max-width: 1200px;
          height: 80%;
          margin-left: 0;  /* Ensure no extra space on the left */
        }
          


        .grid-item {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #393939;
          color: white;
          font-size: 48px;
          font-weight: bold;
        }

        .item-1 {
          background-color: #393939; /* Ensure background color */
          grid-column: 1 / 2;
          display: flex;
          flex-direction: column;
          gap: 20px;
          height: 100%; 
        }


        .custom-button {
          background-color: transparent;
          color: white;
          font-size: 18px;
          padding: 10px 20px;
          transition: background-color 0.3s ease, font-weight 0.3s ease, font-size 0.3s ease;
          cursor: pointer;
        }

        .custom-button:hover {
          background-color: transparent;
          color: white;
          font-weight: 700;
          font-size: 20px;
        }

        .custom-button:active {
          background-color: rgba(255, 255, 255, 0.1);
        }

        .item-2 {
          grid-column: 2 / 2;
          height: 100%;
           background-color: #000000;
        }
      `
      }</style>
    </>
  );
}
