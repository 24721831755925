
import React, { useState, createContext, useContext } from 'react';

// Create a context
const CredentialsContext = createContext();

// Create the provider component
export class CredentialsProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username:'',
      password:'', 
      setUsername:this.setUsername,
      setPassword:this.setPassword
    };
  }
  setUsername = (newUsername) => {
    this.setState({ username: newUsername });
  };
  setPassword = (newPassword) => {
    this.setState({ password: newPassword });
  };

  render() {
    return (
      <CredentialsContext.Provider value={this.state}>
        {this.props.children}
      </CredentialsContext.Provider>
    );
  }
}

// Hook to use the settings in functional components
export const useCredentials = () => {
  return useContext(CredentialsContext);
};