import { Button, Modal, TextInput } from '@carbon/react';
import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { matchImage } from '../utils/Caller';
import { useSettings } from "../provider/SettingsProvider";
import { useCredentials } from '../provider/CredentialsProvider';
import { MatchResult } from '../models/MatchResult';
import { TRUE } from 'sass';

// Add the onUnlockComplete prop type
interface UnlockPageProps {
  onUnlockComplete: () => void;
}

export default function UnlockPage({ onUnlockComplete }: UnlockPageProps) {
  const webcamRef = useRef<Webcam>(null);
  const {matchPercentage, setMatchPercentage } = useSettings();
  const { username, password, setPassword,setUsername } = useCredentials();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  // State for managing username and password input
  const [inputUsername, setInputUsername] = useState<string>('');
  const [inputPassword, setInputPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');

  // Capture the current webcam view and return the image
  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      return imageSrc;
    }
  };

  const handleClear = () => {
    setInputUsername('');
    setInputPassword('');
  };

  const handleButtonClick = async () => {
    console.log('Button was pressed!');

    if (inputUsername.length < 5 || inputPassword.length < 5) {
      setErrorMessage('Please provide a valid username and password (at least 5 characters each).');
      setIsErrorModalOpen(true);
      return;
    }
    
    // Ensure matchImage returns void, and handle undefined return in your logic
    try{
      const result: MatchResult | void = await matchImage(inputUsername,inputPassword, captureImage());
      if (result && result.match_percentage / 100 > matchPercentage) {
        console.log("Unlock successful!");
        setPassword(inputUsername);
        setUsername(inputPassword);
        onUnlockComplete();  // Trigger the onUnlockComplete callback
      } else {
        setErrorMessage('Unlock failed. Try again."');
        setIsErrorModalOpen(true);
      }
    }
    catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message || 'An unexpected error occurred.');
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
      setIsErrorModalOpen(true);
    }
};



  return (
    <>
      <div className="unlock-container">
      <h1 className="title">Unlock</h1>

      {/* Horizontal Layout for Username and Password */}
      <div className="input-container">
        <TextInput
          id="username"
          hideLabel={true}
          labelText="Username"
          placeholder="Username"
          value={inputUsername}
          onChange={(e) => setInputUsername(e.target.value)}
           />
        <TextInput
          id="password"
          hideLabel={true}
           placeholder="Password"
          labelText="Password"
          type="password"
          value={inputPassword}
          onChange={(e) => setInputPassword(e.target.value)}
             />
      </div>

      {/* Live Webcam View */}
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          facingMode: 'user',
        }}
        style={{
          width: '80%',
          height: '400px',
          marginBottom: '0px',
          borderRadius: '8px',
          transform: 'scaleX(-1)', // Mirror image
        }}
      />

      {/* Buttons Layout */}
      <div className="button-container">
        <Button
          onClick={handleClear}
          kind="secondary"
          style={{ width: '48%' }} // Same width as the input fields
        >
          Clear
        </Button>

        <Button
          onClick={handleButtonClick}
          kind="primary"
          style={{ width: '48%' }} // Same width as the input fields
        >
          Unlock
        </Button>

        <Modal
        open={isErrorModalOpen}
        modalHeading="Error"
        primaryButtonText="Close"
        onRequestClose={() => setIsErrorModalOpen(false)}
        onRequestSubmit={() => setIsErrorModalOpen(false)}
      >
        <p>{errorMessage}</p>
      </Modal>
      </div>
    </div>

      <style>{`
        .title {
        
          font-size: 2rem;
          margin-bottom: 4rem;
          }
        .unlock-container {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; 
          height: 90vh;
          padding: 20px;
        }

       .input-container {
          display: flex;
          gap: 10px; 
          justify-content: space-between;
          width: 80%;
          margin: 0 auto 20px;
          margin-bottom: 4rem;
        }

        .webcam {
          width: 80%; /* Adjust width to match input fields */
          height: 400px;
          margin-bottom: 4rem;
        
          transform: scaleX(-1); /* Mirror image */
        }

        .unlock-button {
         width: 800px  /* Full width and aligned at the bottom */
         
        }
         
       .button-container {
          display: flex;
          gap: 10px;
          justify-content: center; /* Align buttons horizontally */
          width: 80%;
          margin-top: 2rem;
        }

       
      `}</style>
    </>
  );
}
