const TitleBar = () => {
  return (
    <>
      <div className="title-bar">
        <span style={{ fontFamily: 'IBM Plex Sans, sans-serif', fontWeight: 400, marginRight: 10, marginLeft: 15 ,background: "#161616"}}>
          IBM
        </span>
        <span className="facevault-text" style={{ fontFamily: 'IBM Plex Sans, sans-serif', fontWeight: 700 }}>
          FaceVault
        </span>
      </div>
      <style>{`
        .title-bar {
          position: fixed; /* Fixes the title bar to the top of the viewport */
          top: 0;
          left: 0;
          width: 100%;
          max-height: 200px; /* Set maximum height */
          overflow: auto;
          display: flex;
          background: #161616;
          justify-content: flex-start; /* Align content to the start (left) */
          color: #fff;
          font: 700 32px IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
          border-bottom: 2px solid #393939; /* Bottom border added */
          z-index: 1000; /* Ensure the title bar stays above other content */
        }

        .facevault-text {
          background: #161616;
          color: #548AF7; /* FaceVault text color */
        }
      `}</style>
    </>
  );
};

export default TitleBar;
