import React, { useRef, useState } from 'react';
import { Button, TextInput, Modal } from '@carbon/react';
import Webcam from 'react-webcam';
import { uploadImage, uploadUser } from '../utils/Caller';

interface SetupPageProps {
  onSetupComplete: () => void; // Callback function type
}

export default function SetupPage({ onSetupComplete }: SetupPageProps) {
  const webcamRef = useRef<Webcam>(null);
  const [inpUsername, setInpUsername] = useState('');
  const [inpPassword, setInpPassword] = useState('');
  const [imageCount, setImageCount] = useState(0);
  const [capturedImages, setCapturedImages] = useState<string[]>([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setCapturedImages((prev) => [...prev, imageSrc]);
        setImageCount((prev) => prev + 1);
      }
    }
  };

  const handleImageUploadClick = async () => {
    if (inpUsername.length < 5 || inpPassword.length < 5) {
      setErrorMessage('Please provide a valid username and password (at least 5 characters each).');
      setIsErrorModalOpen(true);
      return;
    }

    try {
      await uploadUser(inpUsername, inpPassword);

      for (const image of capturedImages) {
        await uploadImage(inpUsername, inpPassword, image);
      }

      alert('Account created successfully!');
      onSetupComplete(); // Notify HomePage to switch to UnlockPage

      setCapturedImages([]);
      setImageCount(0);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message || 'An unexpected error occurred.');
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
      setIsErrorModalOpen(true);
    }
  };

  return (
    <>
      <div className="setup-container">
        <h1 className="title">Setup</h1>
        <div className="input-container">
          <TextInput
            id="username"
            hideLabel={true}
            labelText="Username"
            placeholder="Username"
            value={inpUsername}
            onChange={(e) => setInpUsername(e.target.value)}
          />
          <TextInput
            id="password"
            hideLabel={true}
            placeholder="Password"
            labelText="Password"
            type="password"
            value={inpPassword}
            onChange={(e) => setInpPassword(e.target.value)}
          />
        </div>

        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={{
            width: '600px',
            height: '400px',
            marginBottom: '20px',
            borderRadius: '8px',
            backgroundColor: '#000000',
            transform: 'scaleX(-1)',
          }}
        />

        <p className="image-counter">Images Captured: {imageCount}/4</p>

        <div className="button-container">
          <Button
            kind="secondary"
            onClick={captureImage}
            disabled={imageCount >= 4}
          >
            Add Photo
          </Button>
          <Button
            kind="primary"
            onClick={handleImageUploadClick}
            disabled={imageCount < 4 || !inpUsername || !inpPassword}
          >
            Upload and Create Account
          </Button>
        </div>
      </div>

      <Modal
        open={isErrorModalOpen}
        modalHeading="Error"
        primaryButtonText="Close"
        onRequestClose={() => setIsErrorModalOpen(false)}
        onRequestSubmit={() => setIsErrorModalOpen(false)}
      >
        <p>{errorMessage}</p>
      </Modal>

      <style>{`
        .input-container {
          background-color: #000000;
          display: flex;
          justify-content: center;
          gap: 1rem;
          margin-bottom: 1rem;
        }
        .setup-container {
          background-color: #000000;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          min-height: 100vh;
          padding: 1rem 2rem;
        }
        .title {
         background-color: #000000;
          font-size: 2rem;
          margin-bottom: 1rem;
        }
        .image-counter {
         background-color: #000000;
          margin: 0.5rem 0;
          font-size: 1rem;
          color: #FFFFFF;
        }
        .button-container {
         background-color: #000000;
          display: flex;
          justify-content: center;
          gap: 1rem;
          margin-top: 0.5rem;
        }
       
      `}</style>
    </>
  );
}
