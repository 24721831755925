import './App.css';
import "./styles/carbon.scss";
import "./styles/global.scss";

import React from 'react';

import { SettingsProvider } from "./provider/SettingsProvider";
import { CredentialsProvider } from "./provider/CredentialsProvider";
import Loginpage from "./pages/LoginPage";

function App() {
  return (
    <SettingsProvider>
      <CredentialsProvider>
        <Loginpage />
      </CredentialsProvider>
    </SettingsProvider>
  );
}

export default App;
