import React from 'react';

export default function WebcamPage() {
  return (
    <div>
      <h1>Webcam Configuration</h1>
      <p>feature not yet avalible in FaceVault0.1.</p>
    </div>
  );
}
