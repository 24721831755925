import React, { useEffect, useState } from 'react';
import { returnFiles } from '../utils/Caller';
import { useCredentials } from '../provider/CredentialsProvider';
import tarStream from 'tar-stream';
import { Button, Loading } from '@carbon/react';

const FileManagerComponent: React.FC = () => {
  const { username, password } = useCredentials();
  const [tree, setTree] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTree = async () => {
      const directoryTree = await fetchFile();
      if (directoryTree) {
        setTree(setExpandedState(directoryTree)); // Set expanded state for all nodes
      }
      setLoading(false);
    };

    fetchTree();
  }, []);

  const fetchFile = async () => {
    try {
      console.log(`[${new Date().toISOString()}] Starting fetch for user: ${username}`);
    
      // Fetch file from API
      const file = await returnFiles(username, password);
      console.log(`[${new Date().toISOString()}] File fetched successfully:`, {
        fileName: file.fileName,
      });

      // Create a Blob and File object for processing
      const fileBlob = new Blob([file.fileData], { type: "application/octet-stream" });
      const tarFile = new File([fileBlob], "example.tar", { type: "application/octet-stream" });

      // Parse the TAR file and get the directory tree
      let tree = await getTarDirectoryTree(tarFile);
      console.log("Directory Tree:", JSON.stringify(tree, null, 2)); // Pretty-print the tree
      // Log the directory tree
      if (tree.length > 0) {
        console.log(`[${new Date().toISOString()}] Directory tree parsed successfully:`);
        console.table(tree); // Display in a tabular format for better readability
      } else {
        console.warn(`[${new Date().toISOString()}] Directory tree is empty.`);
      }

      return tree;
    } catch (error) {
      console.error(`[${new Date().toISOString()}] Error fetching file:`, error);
    }
  };

  const getTarDirectoryTree = async (tarFile: File): Promise<any[]> => {
    const directoryTree: any[] = [];
    const extract = tarStream.extract();

    extract.on('entry', (header, stream, next) => {
      const pathParts = header.name.split('/');
      const type = header.type === 'directory' ? 'folder' : 'file';
      const node = { name: pathParts.pop(), path: header.name, type, expanded: true }; // Set expanded to true by default

      let currentLevel = directoryTree;
      pathParts.forEach((part) => {
        let existing = currentLevel.find((item) => item.name === part && item.type === 'folder');
        if (!existing) {
          existing = { name: part, type: 'folder', children: [], expanded: true }; // Set expanded to true by default
          currentLevel.push(existing);
        }
        currentLevel = existing.children;
      });
      if (type === 'file' || type === 'folder') {
        currentLevel.push(node);
      }
      stream.on('end', () => next());
      stream.resume();
    });

    await new Promise((resolve) => {
      extract.on('finish', resolve);
      tarFile.arrayBuffer().then((buffer) => {
        const uint8Array = new Uint8Array(buffer);
        extract.write(uint8Array);
        extract.end();
      });
    });

    return directoryTree;
  };

  // Helper function to set expanded state to true for the root node, false for others
  const setExpandedState = (nodes: any[]): any[] => {
    return nodes.map((node, index) => {
      node.expanded = index === 0; // Root folder expanded by default, others collapsed
      if (node.children) {
        node.children = setExpandedState(node.children); // Recursively set for children
      }
      return node;
    });
  };

  const cleanTree = (node: { children: any[]; }) => {
    if (!node.children) return node;
    node.children = node.children.filter((child) => child.name.trim() !== "");
    node.children.forEach(cleanTree);
    return node;
  };

  const extractFileFromTar = async (tarFile: File, targetPath: string): Promise<Uint8Array | null> => {
    return new Promise((resolve, reject) => {
      const extract = tarStream.extract();
      let fileData: Uint8Array | null = null;

      extract.on("entry", (header, stream, next) => {
        if (header.name === targetPath) {
          const chunks: Uint8Array[] = [];
          stream.on("data", (chunk) => chunks.push(chunk));
          stream.on("end", () => {
            fileData = new Uint8Array(chunks.reduce((acc, chunk) => acc + chunk.length, 0));
            chunks.reduce((offset, chunk) => {
              fileData!.set(chunk, offset);
              return offset + chunk.length;
            }, 0);
            next();
          });
        } else {
          stream.resume();
          next();
        }
      });

      extract.on("finish", () => {
        resolve(fileData);
      });

      extract.on("error", reject);

      tarFile.arrayBuffer()
        .then((buffer) => {
          const uint8Array = new Uint8Array(buffer);
          extract.write(uint8Array);
          extract.end();
        })
        .catch(reject);
    });
  };

  const handleOpen = async (path: string) => {
    console.log(`Open file: ${path}`);

    try {
      // Fetch the TAR file from the API
      const file = await returnFiles(username, password);
      const fileBlob = new Blob([file.fileData], { type: "application/octet-stream" });
      const tarFile = new File([fileBlob], "example.tar", { type: "application/octet-stream" });

      // Extract the specific file from the TAR archive
      const fileData = await extractFileFromTar(tarFile, path);

      if (!fileData) {
        console.error(`File not found: ${path}`);
        return;
      }

      // Create a blob and download link for the file
      const extractedBlob = new Blob([fileData], { type: "application/octet-stream" });
      const url = URL.createObjectURL(extractedBlob);

      // Trigger file download
      const a = document.createElement("a");
      a.href = url;
      a.download = path.split("/").pop() || "downloaded-file";
      a.click();
      URL.revokeObjectURL(url);

      console.log(`File successfully downloaded: ${path}`);
    } catch (error) {
      console.error(`Error opening file: ${path}`, error);
    }
  };

  const handleUpload = (path: string) => {
    console.log(`Upload folder: ${path}`);
    // Implement your "upload" logic here
    alert("feature not yet avalible in FaceVault0.1.");
  };

  const handleDelete = (path: string) => {
    console.log(`Delete folder: ${path}`);
    // Implement your "delete" logic here
    alert("feature not yet avalible in FaceVault0.1.");
  };

  const RenderTreeItem = ({ node }: { node: any }) => {
    const [isExpanded, setIsExpanded] = useState(node.expanded);

    const toggleExpanded = () => {
      setIsExpanded(!isExpanded);
    };

    return (
      <div className="tree-item" style={{ marginLeft: node.type === 'folder' ? '20px' : '40px' }}>
        <div className="tree-item-header">
          <button
            onClick={toggleExpanded}
            className="expand-button"
          >
            {isExpanded ? '−' : '+'} {node.name}
          </button>

          {/* Render the action buttons inline when expanded */}
          {isExpanded && (
            <div className="action-buttons">
              {node.type === "file" && (
                <>
                  <Button className="action-button" size="sm" onClick={() => handleOpen(node.path)}>
                    Open
                  </Button>
                  <Button className="action-button" size="sm" onClick={() => handleDelete(node.path)}>
                    Delete
                  </Button>
                </>
              )}
              {node.type === "folder" && (
                <Button className="action-button" size="sm" onClick={() => handleUpload(node.path)}>
                  Upload
                </Button>
              )}
            </div>
          )}
        </div>
        {isExpanded && node.children && node.children.map((child: any, index: number) => (
          <RenderTreeItem key={index} node={child} />
        ))}
      </div>
    );
  };

  return (
    <div className="container">
      <div className="file_container">
      {loading ? <Loading withOverlay={true} /> : (
        tree.map((rootNode, index) => (
          <RenderTreeItem key={index} node={rootNode} />
        ))
      )}
      </div>

      <style>{`
        * {
          background-color: black;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: 'IBM Plex Sans', sans-serif;
        }

       .container {
          padding: 20px;
          width: 100%;            /* Ensure the container fills the available width */
          height: 100%;           /* Ensure the container fills the available height */
          box-sizing: border-box; /* Include padding in the element's total width and height */
          overflow: auto;         /* Enable scrolling when content overflows */
        }

       
       .file_container {
        max-height: 870px;
        overflow-y: auto;
        margin-left: 20px;   /* Adjust the margin as needed */
        padding: 10px;       /* Optional padding inside the container */
        display: flex;
        flex-direction: column;  /* Align dropdowns vertically */
        gap: 10px;               /* Space between dropdowns */
        background-color: #222;  /* Background color of the container */
        border-radius: 5px;      /* Optional rounded corners */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  /* Optional shadow for better visibility */
      }
       
        .tree-item {
          color: white;
        }

        .tree-item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }

        .expand-button {
          cursor: pointer;
          padding: 5px 10px;
          border: none;
          background-color: transparent;
          color: white;
          font-size: 16px;
        }

        .expand-button:hover {
          color: #ddd;
        }

        .action-button {
          width: 120px;
          height: 40px;
          padding: 10px;
          margin: 5px;
        }

        .action-buttons button {
          background-color: #333;
          color: white;
          border: none;
          padding: 5px 10px;
        }

        .action-buttons button:hover {
          background-color: #444;
        }
      `}</style>
    </div>
  );
};

export default FileManagerComponent;
