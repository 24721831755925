import { Button, FileUploader, Loading } from '@carbon/react';
import React, { useState } from 'react';
import { replaceDirectory } from '../utils/Caller'; // Adjust the import path as necessary
import { useCredentials } from '../provider/CredentialsProvider';

// TODO: move all functionality to storage page
export default function StoragePage() {
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { username, password } = useCredentials();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files) {
      const uploadedFiles = Array.from(event.target.files) as File[];
      setFiles(uploadedFiles);

      uploadedFiles.forEach((file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target?.result) {
            const fileData = e.target.result;
            console.log(`File: ${file.name}, Data: ${fileData}`);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    }
  };

  const handleFileUploadReq = async () => {
    if (files.length === 0) {
      console.error("No files selected for upload.");
      alert("Please select at least one file.");
      return;
    }

    if (!username || !password) {
      console.error("Username or password missing.");
      alert("Please enter your username and password.");
      return;
    }

    setIsLoading(true); // Start loading
    try {
      for (const file of files) {
        console.log(`Uploading file: ${file.name}`);
        await replaceDirectory(username, password, file);
        console.log(`File ${file.name} uploaded successfully.`);
      }
      alert("All files uploaded successfully.");
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("An error occurred during file upload. Please try again.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <>
      <div className="setup-container">
        {isLoading && <Loading description="Uploading files..." withOverlay={true} />}
        <h1>Storage Configuration</h1>
        <p>Configure your storage options here.</p>

        <FileUploader
          accept={['.tar']}
          buttonKind="secondary"
          buttonLabel="Upload Files"
          filenameStatus="edit"
          iconDescription="Clear file"
          labelDescription="Upload a directory or multiple files."
          labelTitle="Select .Tar Files to Upload"
          multiple={true}
          onChange={handleFileUpload}
          style={{ margin: '70px', backgroundColor: '#000000' }}
        />

        <Button
          className="upload-button"
          onClick={handleFileUploadReq}
          kind="primary"
          disabled={isLoading} // Disable the button while loading
        >
          {isLoading ? "Uploading..." : "Upload Files"}
        </Button>
      </div>
      <style>{`
        .setup-container {
          background-color: #000000;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 80vh;
          padding: 20px 20px;
          position: relative;
          margin-top: 50px; 
          margin-bottom: 50px; 
        }
      `}</style>
    </>
  );
}
