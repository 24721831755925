


import TitleBar from "../ui-components/TitleBar"
import HomePage from "../ui-components/HomePage"


import { Theme, Content } from "@carbon/react";
import React from "react";

function LoginPage() {
    return (
      <div className="App">
       
       <Theme theme="g100" className="Theme">
                  <TitleBar />
                  <Content className="carbon-content" id="carbon-content" >
                  <HomePage/>
                  </Content>
        </Theme>
      </div>
    );
  }

  export default LoginPage;